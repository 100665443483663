import { useContext } from 'react'
import { ProfileContext } from './ProfileContext'

const useProfile = () => {
  const context = useContext(ProfileContext)
  const { profile: stateProfile, setProfile: setStateProfile, setStorageProfile: setLocalStorageProfile } = context

  // update both local storage and state profile
  const updateProfile = (profile) => {
    setStateProfile(profile)
    setLocalStorageProfile(profile)
  }

  const modifyHeroStat = (hero, type, reverse = false) => {
    let newProfile, netGuardianPointsChange

    if (type === 'hero') {
      // limit break requires max evolution
      let originalEvolution = hero.evolution
      hero.evolution = hero.heroLimitBreak === 0 ? getMaxEvoStage(hero) : hero.evolution
      hero.heroLimitBreak = hero.heroLimitBreak >= 5 || originalEvolution === -1 ? 0 : hero.heroLimitBreak + 1
      netGuardianPointsChange = hero.guardianPoints
      hero.guardianPoints = calculateGuardianPoints(hero)
      netGuardianPointsChange = hero.guardianPoints - netGuardianPointsChange
      newProfile = {
        ...stateProfile,
        myHeroes: stateProfile.myHeroes.map((e) => (e.id === hero.id ? hero : e)),
        hasModified: true,
        totalGuardianPoints: stateProfile.totalGuardianPoints + netGuardianPointsChange,
      }
    } else if (type === 'weapon') {
      hero.weaponLimitBreak = hero.weaponLimitBreak >= 5 || reverse ? -1 : hero.weaponLimitBreak + 1
      netGuardianPointsChange = hero.guardianPoints
      hero.guardianPoints = calculateGuardianPoints(hero)
      netGuardianPointsChange = hero.guardianPoints - netGuardianPointsChange
      newProfile = {
        ...stateProfile,
        myHeroes: stateProfile.myHeroes.map((e) => (e.id === hero.id ? hero : e)),
        hasModified: true,
        totalGuardianPoints: stateProfile.totalGuardianPoints + netGuardianPointsChange,
      }
    } else if (type === 'evolution') {
      // only operate downwards, either clear limit break or down evolve
      if (hero.heroLimitBreak === 0) hero.evolution = hero.evolution - 1
      hero.heroLimitBreak = 0
      netGuardianPointsChange = hero.guardianPoints
      hero.guardianPoints = calculateGuardianPoints(hero)
      netGuardianPointsChange = hero.guardianPoints - netGuardianPointsChange
      newProfile = {
        ...stateProfile,
        myHeroes: stateProfile.myHeroes.map((e) => (e.id === hero.id ? hero : e)),
        hasModified: true,
        totalGuardianPoints: stateProfile.totalGuardianPoints + netGuardianPointsChange,
      }
    }
    updateProfile(newProfile)
  }
  return [stateProfile, modifyHeroStat, getMaxEvoStage]
}

export const calculateGuardianPoints = (hero) => {
  let hasHero = hero.evolution > -1
  let hasWeapon = hero.weaponLimitBreak > -1
  return (
    (hasHero ? 300 + hero.evolution * 60 : 0) +
    hero.heroLimitBreak * 60 +
    (hasWeapon ? 300 + hero.weaponLimitBreak * 60 : 0)
  )
}

export const getMaxEvoStage = (hero) => {
  // should return 2 or 3 upon rarity
  return 5 - hero.rarity
}

export const getHeroEntity = (node) => {
  return {
    id: node.contentful_id,
    name: node.name,
    element: {
      id: node.element.contentful_id,
      order: node.element.order,
    },
    class: { id: node.class.contentful_id, order: node.class.order },
    gatsbyImageData: node.thumbnail.gatsbyImageData,
    rarity: node.rarity,
    releaseDate: node.releaseDate,
    evolution: -1,
    heroLimitBreak: 0,
    weaponLimitBreak: -1,
    guardianPoints: 0,
  }
}

export default useProfile
