import React, { createContext, useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { createLocalStorageStateHook } from 'use-local-storage-state'
import useGaTracker from './useGaTracker'
import { getHeroEntity } from './useProfile'
import _, { orderBy } from 'lodash'

const PROFILE_VERSION = 1

// create context
export const ProfileContext = createContext({
  profile: {},
  setProfile: () => {},
  setStorageProfile: () => {},
  resetProfile: () => {},
})

const ProfileContextProvider = ({ children }) => {
  useGaTracker()

  // query heroes of default locales 'en'
  // heroes: allContentfulHeroes(filter: { node_locale: { eq: "en" } }) {
  const query = useStaticQuery(graphql`
    query HeroesQuery {
      heroes: allContentfulHeroes {
        edges {
          node {
            name
            contentful_id
            node_locale
            thumbnail {
              gatsbyImageData(quality: 100, layout: CONSTRAINED, width: 110)
            }
            element {
              contentful_id
              order
            }
            class {
              contentful_id
              order
            }
            rarity
            releaseDate
          }
        }
      }
    }
  `)
  // function to get default heroes ordered by element
  const getDefaultHeroes = (locale = 'en') => {
    return orderBy(
      query.heroes.edges?.filter((e) => e.node.node_locale === locale).map((e) => getHeroEntity(e.node)),
      ['element.order'],
      ['asc']
    )
  }
  // locale storage for profile
  const useLocalStorageProfile = createLocalStorageStateHook('profile')
  const [localStorageProfile, setLocalStorageProfile] = useLocalStorageProfile()
  // use default heroes as profile if no records from local storage
  const [stateProfile, setStateProfile] = useState(
    localStorageProfile && localStorageProfile.version && localStorageProfile.version >= PROFILE_VERSION
      ? localStorageProfile
      : {
          myHeroes: getDefaultHeroes('en'),
          hasModified: false,
          totalGuardianPoints: 0,
          locale: 'en',
          version: PROFILE_VERSION,
        }
  )
  // exchange
  const updateLocale = (locale) => {
    if (locale !== stateProfile.locale) {
      let localeHeroes = getDefaultHeroes(locale)
      setStateProfile((prevState) => ({
        ...prevState,
        locale: locale,
        myHeroes: prevState.myHeroes.map((e) => ({
          ...e,
          name: localeHeroes.find((h) => h.id === e.id).name,
        })),
      }))
    }
  }
  // sort profile and merge with any new heroes by default
  useEffect(() => {
    setStateProfile((prevState) => ({
      ...prevState,
      myHeroes: orderBy(
        _.merge(_.keyBy(getDefaultHeroes(), 'id'), _.keyBy(prevState.myHeroes, 'id')),
        ['element.order'],
        ['asc']
      ),
    }))
  }, [])
  // function to reset default profile
  const resetProfile = (locale) => {
    let profile = {
      myHeroes: getDefaultHeroes(locale),
      hasModified: false,
      totalGuardianPoints: 0,
      locale: locale,
      version: PROFILE_VERSION,
    }
    setStateProfile(profile)
    setLocalStorageProfile(profile)
  }
  return (
    <ProfileContext.Provider
      value={{
        profile: stateProfile,
        setProfile: setStateProfile,
        setStorageProfile: setLocalStorageProfile,
        getDefaultHeroes: getDefaultHeroes,
        resetProfile: resetProfile,
        updateLocale: updateLocale,
      }}
    >
      {children}
    </ProfileContext.Provider>
  )
}

export default ProfileContextProvider
