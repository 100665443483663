import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'

const useGaTracker = () => {
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    ReactGA.initialize('G-6MNTBJVT99')
    setInitialized(true)
  }, [])

  useEffect(() => {
    if (initialized) {
      ReactGA.send('pageview')
    }
  }, [initialized])
}

export default useGaTracker
